<template>
  <div :class="{'dropdown-error': fieldError}" class="dropdown-wrapper">
    <!-- The title of the field -->
    <div v-if="fieldTitle" class="dropdown-title-box">
      <p class="dropdown-title form-input-title">
        {{ fieldTitle }}
        <span v-if="inlineSubtitle" class="inline-subtitle">({{ inlineSubtitle }})</span>
      </p>
    </div>

    <div :id="`dropdown-toggle-${id}`" class="basic-dropdown">

      <!-- The dropdown input -->
      <!-- TODO: Modify alt texts either with Dutch texts or whatever suits best here.. -->
      <div class="dropdown-text-container" @click="toggleDropdown()">
        <InlineSvg :src="require('../../assets/icons/icn_arrow.svg')" class="dropdown-icon" :class="{'foldout': isDropdownShown}" />
        <InputField :field-title="''"
                    :field-name="fieldName"
                    :rules="rules"
                    :type="'text'"
                    :is-read-only="true"
                    :placeholder="placeholder"
                    :value="selected ? selected : ''"
                    :input-icon="inputIcon"
                    :input-icon-error="inputIconError"
                    :debounce="0"
                    :inline-subtitle="inlineSubtitle"
                    :api-error-text="apiErrorText"
                    error-icon-offset="2rem"/>
      </div>

      <!-- The dropdown list -->
      <div v-show="isDropdownShown" :id="`option-list-${id}`" class="option-list">
        <div class="option-list-inner">
          <div v-for="option in dropdownOptions"
               :id="`option-${option.id}`"
               :key="option.id"
               class="option"
               @click="selectOption(option)">
            <SelectedIndicator :selected="selectedList.some(value => value.id === option.id)"/>
            <div class="option-text"> {{ option.name }}</div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import InputField from '@/components/yo-form/InputField'
import { ref, watch, onUnmounted, computed } from 'vue'
import { addListener, removeListener } from '@/utils/helpers/listenerHelper'
import SelectedIndicator from '@/components/elements/inputFields/SelectedIndicator'
import InlineSvg from 'vue-inline-svg'

export default {
  /** TODO: We could potentially merge Dropdown and DropdownWithCheckboxes. **/
  name: 'DropdownWithCheckboxes',
  components: {
    InputField,
    InlineSvg,
    SelectedIndicator
  },
  props: {
    dropdownOptions: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    fieldTitle: {
      type: String,
      required: false,
      default: undefined
    },
    fieldName: {
      type: String,
      required: true,
    },
    selectedOptions: {
      type: Array,
      required: false,
      default: () => []
    },
    callback: {
      type: Function,
      required: true
    },
    fieldError: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: String,
      required: false,
      default: 'default'
    },
    inputIcon: {
      type: String,
      required: false,
      default: ''
    },
    inputIconError: {
      type: String,
      required: false,
      default: ''
    },
    apiErrorText: {
      type: String,
      required: false,
      default: ''
    },
    rules: {
      type: String,
      required: false,
      default: 'required'
    },
    inlineSubtitle: {
      type: String,
      required: false,
      default: ''
    },
  },
  setup(props) {
    const isDropdownShown = ref(false)
    const selectedList = ref([...props.selectedOptions])
    const selected = computed(() => getStringNames())
    setValues()
    props.callback(selectedList.value)

    /** On initialization **/
    function setValues() {
      //selected.value = props.selectedOption
    }



    // If there is a new value passed from the parent, the dropdown should display that new value.
    watch(() => props.selectedOptions, (newValue, prevValue) => {
      if (newValue) {
        selectedList.value = [...newValue]
      }
    })

    /** Toggling **/
    function toggleDropdown() {
      isDropdownShown.value = !isDropdownShown.value
      // If the dropdown option list is displayed, the click event should be listened to.
      isDropdownShown.value ? addListener('click', clickEvent) : removeListener('click', clickEvent)
    }

    /** On option click **/
    const clickEvent = (event) => {
      if (!eventContainsElement(event, `option-list-${props.id}`) && !eventContainsElement(event, `dropdown-toggle-${props.id}`)) {
        toggleDropdown()
      }
    }

    function selectOption(option) {
      addToSelectedList(option)
      props.callback(selectedList.value)
    }

    /** Selected items **/
    function addToSelectedList(item) {
      // Checks if the clicked item already exists. If it does it is added, otherwise it is removed.
      const exists = selectedList.value.find(data => data.id === item.id)
      !exists ? addItem(item) : removeItem(item)
    }

    function addItem(item) {
      selectedList.value.push(item)
    }

    function removeItem(item) {
      selectedList.value = selectedList.value.filter(data => data.id !== item.id)
    }

    function getStringNames() {
      let selectedString = ''

      selectedList.value.map(item => {
        selectedString += selectedString ? `, ${item.name}` : item.name
      })

      return selectedString
    }

    /** Other helper functions **/
    function eventContainsElement(event, elementId) {
      return document.getElementById(elementId).contains(event.target)
    }

    /** On exit **/
    // When the component is unmounted, the listeners should be removed.
    onUnmounted(() => {
      removeListener('click', clickEvent)
    })

    return {
      isDropdownShown,
      selected,

      /** Toggling **/
      toggleDropdown,

      /** On option click **/
      selectOption,

      /** Selected items **/
      selectedList
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.variables";
@import "../../assets/css/base.mixins";

$background-color: white;
$border-color: #1c5aeb18;
$text-color: var(--blue_dark_01);

.basic-dropdown {
  position: relative;

  &:hover {
    cursor: pointer;
  }

  .dropdown-text-container {
    align-items: center;
    display: flex;
    height: 100%;
  }

  .option-list {
    @include position-elevation(absolute, $top: 100%, $right: 0, $left: 0, $elevation: 15);
    border-radius: rem(10);
    background-color: #FFFFFF;
    box-shadow: rem(2) rem(2) 0 0 rgba(0, 0, 0, 0.1);

    .option-list-inner {
      padding-right: rem(4);
      position: relative;

      &::-webkit-scrollbar {
        margin-top: rem(20);
      }
    }
  }

  .option {
    padding: rem(14) rem(20);
    display: flex;
    gap: rem(20);
    flex-wrap: nowrap;
    line-height: rem(19);

    &:not(:last-of-type) {
      border-bottom: rem(1) solid $border-color;
    }
  }

  .option-list .option {
    font-size: rem(16);

    &:hover {
      background-color: rgba(232, 232, 243, 0.226);
      cursor: pointer;
    }
  }

  .selected-option {
    border-radius: rem(10);
  }
}

.dropdown-icon {
  //@include position-elevation(absolute, $top: rem(12), $right: rem(12), $elevation: 10);
  position: absolute;
  z-index: 1;
  width: rem(10);
  height: rem(10);
  top: rem(18);
  right: rem(17);
  :deep g path {
    fill: var(--blue_light_01);
  }
}

.dropdown-icon.foldout {
  animation: rotateOpen .25s forwards;
}

.dropdown-icon:not(.foldout) {
  animation: rotateClosed .25s forwards;
}

@keyframes rotateOpen {
  0% { rotate: 0deg }
  100% { rotate: 90deg }
}

@keyframes rotateClosed {
  0% { rotate: 90deg }
  100% { rotate: 0deg }
}

.dropdown-error {
  .basic-dropdown {
    border: 1px solid var(--red_error);
  }

  .dropdown-title, .dropdown-text {
    color: var(--red_error);
  }
}
</style>
